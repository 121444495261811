

import { Easing } from './_utils/easing';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { CustomMsalInterceptor } from './_auth/custom-msal-interceptor';

import { msalConfig, loginRequest, protectedResources } from './auth-config';
// PRIME NG
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AppComponent } from './app.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule} from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { MessageModule } from 'primeng/message'; 
import { MessagesModule } from 'primeng/messages';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule} from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TreeTableModule } from 'primeng/treetable';
import {ToastModule} from 'primeng/toast';
//import * as angulartics2 from 'angulartics2';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
 
import { ConfigService } from './_config';
import { ValidationMessagesComponent, ValidationService } from './_directives/validation';
import { ValidationSummaryComponent } from './_directives/validation-summary';
import { EnvironmentComponent, HeaderComponent, FooterComponent } from './views/layout';
import { QuoteComponent } from './views/quote';
import { ErrorComponent } from './views/error';
import { routes } from './app.routes';


// Services for Number Picker Application Application
import { FilterService } from './views/shared/filter/filter.service';
import { MessageService } from 'primeng/api';
import { QuoteService } from './views/quote/quote.service';
// Sessions (Singletons)
import { QuoteSession } from './views/quote/quote.session';
import { EngineeringRequestAdditionComponent } from './views/quote/number-addition/engineering-request-addition/engineering-request-addition.component';
import { FilterERComponent } from './views/shared/filter/filter-er/filter-er.component';
import { FilterMPCComponent } from './views/shared/filter/filter-mpc/filter-mpc.component';
import { FilterUserComponent } from './views/shared/filter/filter-user/filter-user.component';
import { MajorProductCodeAdditionComponent } from './views/quote/number-addition/major-product-code-addition/major-product-code-addition.component';
import { ModificationSuccessComponent } from './views/shared/popup/modification-success/modification-success.component';
import { MultipleNumberEditionComponent } from './views/quote/number-addition/multiple-number-edition/multiple-number-edition.component';
import { NumberHistoryComponent } from './views/quote/search-table/number-history/number-history.component';
import { NumberAdditionComponent } from './views/quote/number-addition/number-addition.component';
import { SearchTableComponent } from './views/quote/search-table/search-table.component';
import { SingleAdditionSuccessComponent } from './views/shared/popup/single-addition-success/single-addition-success.component';
import { FileNumberAdditionComponent } from './views/quote/number-addition/file-number-addition/file-number-addition.component';
import { FilterFileNumberComponent } from './views/shared/filter/filter-file-number/filter-file-number.component';



/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

 export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
  }
  
  /**
   * MSAL Angular will automatically retrieve tokens for resources 
   * added to protectedResourceMap. For more info, visit: 
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
   */
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(protectedResources.numpickApi.endpoint, protectedResources.numpickApi.scopes);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }
  
  /**
   * Set your default interaction type for MSALGuard here. If you have any
   * additional scopes you want the user to consent upon login, add them here as well.
   */
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return { 
      interactionType: InteractionType.Redirect,
      authRequest: loginRequest
    };
  }

@NgModule({
    declarations: [
        AppComponent,
        ValidationMessagesComponent,
        ValidationSummaryComponent,
        EnvironmentComponent,
        FilterERComponent,
        FilterMPCComponent,
        FilterUserComponent,
        HeaderComponent,
        FooterComponent,
        ErrorComponent,
        QuoteComponent,
        EngineeringRequestAdditionComponent,
        MajorProductCodeAdditionComponent,
        ModificationSuccessComponent,
        MultipleNumberEditionComponent,
        NumberAdditionComponent,
        NumberHistoryComponent,
        SearchTableComponent,
        SingleAdditionSuccessComponent,
        FileNumberAdditionComponent,
        FilterFileNumberComponent
    ],
    imports: [
        AutoCompleteModule,
        Angulartics2Module,
        BrowserModule,
        BrowserAnimationsModule,
        ConfirmDialogModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' }),
        Angulartics2Module.forRoot(),
        ButtonModule,
        CheckboxModule,
        DialogModule,
        DropdownModule,
        FormsModule,
        FieldsetModule,
        InputMaskModule,
        InputTextModule,
        InputTextareaModule,
        MessageModule,
        MessagesModule,
        MsalModule,
        MultiSelectModule,
        Ng2GoogleChartsModule,
        NgxPageScrollModule,
        NgxPageScrollCoreModule.forRoot({duration:600, scrollOffset: 75, interruptible: false, easingLogic: (t: number, b: number, c: number, d: number): number => { return Easing.easeInOutQuad(t, b, c, d)}}),
        PanelModule,
        ProgressSpinnerModule,
        SidebarModule,
        SelectButtonModule,
        RadioButtonModule,
        TableModule,
        TabViewModule,
        TreeTableModule,
        ToastModule
    ],
    providers: [
        ConfigService,
        ConfirmationService,
        FilterService,
        ValidationService,
        MessageService,
        QuoteService,
        QuoteSession,
        Angulartics2GoogleAnalytics,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule  { 
    constructor() {
    } 
}