import { BaseComponent } from "../../../../_utils";
import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { QuoteService } from '../../quote.service';
import { QuoteSession } from '../../quote.session';;
import { _ActiveNumber } from "../../../../_models/_ActiveNumber";
import { FilterService } from "../../../shared/filter/filter.service";
import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'app-multiple-number-edition',
    templateUrl: './multiple-number-edition.component.html',
    styleUrls: ['./multiple-number-edition.component.css']
})
export class MultipleNumberEditionComponent extends BaseComponent implements OnInit {
    private modifiedRowDataList: _ActiveNumber[] = [];
    private originalRowDataList: any[] = [];

    public cols: any;
    public displayMultipleNumberEdition: boolean = false;
    public multipleNumberEditionList: _ActiveNumber[] = [];
    public selectedRow: _ActiveNumber;
    public hasSerialNumber: boolean;
    public hasFileNumber: boolean;

    constructor(
        private _confirmationService: ConfirmationService,
        private _filterService: FilterService,
        private _quoteSession: QuoteSession,
        private _quoteService: QuoteService,
        private _messageService: MessageService,
        public _angulartics: Angulartics2) { super() }

    ngOnInit() {
        this.initMultipleEditionNumberList();
    }

    //#region INIT
    private initMultipleEditionNumberList(): void {
        this._quoteSession.multipleNumberEditionRequested
            .subscribe(editionList => {
                this.cols = this._quoteSession.numberEditionCols;
                this.hasSerialNumber = this._quoteSession.hasSerialNumber;
                this.hasFileNumber = this._quoteSession.hasFileNumber;
                this.multipleNumberEditionList = editionList;
                this.modifiedRowDataList = [];
                this.originalRowDataList = [];
                this.displayMultipleNumberEdition = true;
            });
    }
    //#endregion INIT


    //#region UPDATE NUMBER
    public updateNewNumbers(): void {
        if (this._filterService.verifyMPCs(this.modifiedRowDataList, this._quoteSession.mpcs)) {
            if (this._filterService.verifyDescription(this.modifiedRowDataList, this._quoteSession.mpcs)) {
                if (this.modifiedRowDataList.length > 0) {
                    this._angulartics.eventTrack.next({ action: 'Generate Tab-Save Changes Button', properties: { category: this.modifiedRowDataList.length.toString() + 'Modified rows', label: this._quoteSession.defaultUser, value: 1 } });

                    this.modifiedRowDataList.forEach(rowData => {
                        rowData["formattedEngineeringRequestNumber"] = this.convertToNumericERNumber(rowData["formattedEngineeringRequestNumber"]);
                    });

                    this.displayMultipleNumberEdition = false;
                    this._quoteService.putUpdatedNumber(this.serialiseFormattedNumber(this.modifiedRowDataList))
                        .subscribe(
                            rowDataList => this.onUpdateSuccess(rowDataList),
                            error => this.onUpdateError(error)
                        );
                }
                else {
                    this._angulartics.eventTrack.next({ action: 'Generate Tab-Save Changes Button', properties: { category: 'Error-No rows were modified in the table.', label: this._quoteSession.defaultUser, value: 1 } });

                    this.addGrowlMsg(false, "No rows were modified in the table.");
                }
            }
            else {
                this._angulartics.eventTrack.next({ action: 'Generate Tab-Save Changes Button', properties: { category: 'Error-Please specify a description.', label: this._quoteSession.defaultUser, value: 2 } });
                this.addGrowlMsg(false, "Please specify a description.");
            }

        }
        else {
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Save Changes Button', properties: { category: 'Error-Please select a valid MPC from the list or create a new MPC.', label: this._quoteSession.defaultUser, value: 1 } });
            this.addGrowlMsg(false, "Please select a valid MPC from the list or create a new MPC.");
        }
    }


    private onUpdateSuccess(rowDataList: any): void {
        let modifiedSuccessList: _ActiveNumber[] = this.deserialiseFormattedNumber(rowDataList);
        if (rowDataList != false && this.verifyEditSuccess(modifiedSuccessList)) {
            this.displayMultipleNumberEdition = false;
            this._quoteSession.quoteLoaded.emit();
            this._quoteSession.modifiedSuccessListLoaded.emit(modifiedSuccessList);

            this._angulartics.eventTrack.next({ action: 'Generate Tab-Save Changes Button', properties: { category: '', label: this._quoteSession.defaultUser, value: 1 } });
        }
        else {
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Save Changes Button', properties: { category: 'There was an error whilst updating the records!', label: this._quoteSession.defaultUser, value: 1 } });
            this.addGrowlMsg(false, "There was an error whilst updating the records!");
        }
    }


    private onUpdateError(error: string): void {
        this._angulartics.eventTrack.next({ action: 'Generate Tab-Save Changes Button', properties: { category: 'Error-' + error, label: this._quoteSession.defaultUser, value: 1 } });

        console.log(error);
        this.addGrowlMsg(false, error);
        if (error.toString().substring(0, 3) == "403")
            this._quoteSession.forbiddenErrorLoaded.emit();
    }


    private verifyEditSuccess(modifiedSuccessList: _ActiveNumber[]): boolean {
        let toReturn: boolean = true;
        if (this.modifiedRowDataList.length != 0 && this.modifiedRowDataList.length == modifiedSuccessList.length) {
            for (var i = 0; i < this.modifiedRowDataList.length; i++) {
                if (this.modifiedRowDataList[i].formattedNumber != modifiedSuccessList[i].formattedNumber) {
                    toReturn = false;
                }
            }
        }
        return toReturn;
    }
    //#endregion UPDATE NUMBER


    //#region ROW HIGHLIGHT
    public onRowSelect(rowData: _ActiveNumber): void {
        this._filterService.addOriginalRowData(rowData, this.originalRowDataList);
    }


    public onNgModelChange(rowData: _ActiveNumber) {
        this._quoteSession.hasModifications = this._filterService.addRowData(rowData, this.modifiedRowDataList, this.originalRowDataList, null, null, null, false);
    }
    //#endregion ROW HIGHLIGHT


    //#region HELPER METHODS
    private resetAllVars(): void {
        this.modifiedRowDataList = [];
        this.originalRowDataList = [];
        this.cols = [];
        this.displayMultipleNumberEdition = false;;
        this.multipleNumberEditionList = [];
        this.selectedRow = null;
    }


    public onClickAddMoreBtn(): void {
        if (this.modifiedRowDataList.length == 0) {
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Add More Button', properties: { category: '', label: this._quoteSession.defaultUser, value: 1 } });

            this.resetAllVars();
            this._quoteSession.addMoreRequested.emit();
        } else {
            this._angulartics.eventTrack.next({ action: 'Generate Tab-Add More Button', properties: {category: 'You have ' + this.modifiedRowDataList.length + ' unsaved changes', label: this._quoteSession.defaultUser, value: 1 } });

            this._confirmationService.confirm({
                message: 'Are you sure to leave edition page? You have ' + this.modifiedRowDataList.length + ' unsaved changes',
                accept: () => {
                    this.resetAllVars();
                    this._quoteSession.addMoreRequested.emit();
                }
            });
        }
    }


    private addGrowlMsg(success: boolean, message: string): void {
        this._messageService.clear();
        if (success)
            this._messageService.add({ severity: 'success', summary: 'Success', detail: message });
        else
            this._messageService.add({ severity: 'error', summary: 'Failed', detail: message });
    }
    //#endregion HELPER METHOD
}
