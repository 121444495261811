<p-autoComplete 
    styleClass="filter-component"
    appendTo="body"
    maxlength="8"
    [(ngModel)]="selectedFileNumber"
    [suggestions]="filteredFileNumbers"
    (onSelect)="onSelect($event);"
    placeholder="{{placeholder}}"
    (onFocus)="onFocus(rowData);"
    (onBlur)="onBlur(rowData);"
    (keydown.tab)="onBlur(rowData);"
    (keydown.shift.tab)="onBlur(rowData);"
    (onClear)="onClear()"
    (completeMethod)="onCompleteMethod($event)"
    [minLength]="2"
    (ngModelChange)="onNgModelChange(rowData);"
    [multiple]="false" 
    [forceSelection]="false">
</p-autoComplete>
