import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from "../../../../_utils";
import { QuoteService } from '../../quote.service';
import { QuoteSession } from '../../quote.session';

@Component({
  selector: 'app-number-history',
  templateUrl: './number-history.component.html',
  styleUrls: ['./number-history.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NumberHistoryComponent extends BaseComponent implements OnInit {

  public rowDataHistory: any;
  public displayNumberHistory: boolean = false;
  public numberHistory: any;

  public cols: any [] = [
    { field: 'status', header: 'Status' },
    { field: 'formattedEngineeringRequestNumber', header: 'ER' },
    { field: 'majorProductCode', header: 'MPC' },
    {field: 'fileNumber', header: 'File Number' },
    { field: 'description', header: 'Description' },
    { field: 'createdBy', header: 'Modified By' },
    { field: 'createdUtc', header: 'Modified' }
  ];

  constructor(
    private cd: ChangeDetectorRef,
    private _quoteService: QuoteService,
    private _quoteSession: QuoteSession
  ) { 
    super();
  }

  ngOnInit() {
    this._quoteSession.numberHistoryRequested
      .subscribe( rowData => {
        this.rowDataHistory = rowData;
        this._quoteService.getNumberHistory( rowData["numberId"] )
          .subscribe( numberHistory => {
            this.numberHistory = numberHistory;
            this.cd.detectChanges();
            this.displayNumberHistory = true;
        });
    });
  }


  public convertToLocalTime(createdUtc: string): string {
    return this.convertToLocalTimeString(createdUtc, false, "second");
  }
}
