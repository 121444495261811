import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "../../../../_utils";
import { _ActiveNumber } from '../../../../_models/_ActiveNumber';
import { _NewNumberRequest } from '../../../../_models/_NewNumberRequest';
import { QuoteSession } from '../../quote.session';
import { QuoteService } from '../../quote.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-file-number-addition',
    templateUrl: './file-number-addition.component.html',
    styleUrls: ['./file-number-addition.component.css']
})
export class FileNumberAdditionComponent extends BaseComponent implements OnInit {

    private newFileNumber: _ActiveNumber;
    public newFileNumberStr: string;
    public newFileNumberDescription: string = "";
    public displayCreateFileNumber: boolean = false;

    constructor(
        private _quoteSession: QuoteSession,
        private _quoteService: QuoteService,
        private _messageService: MessageService,
    ) { 
        super();
    }

    ngOnInit(): void {
        this._quoteSession.quoteLoaded
        .subscribe(() => {
            this.displayCreateFileNumber = false;
        });

        this.onFileNumberRequested();
    }

    //#region INIT
    private onFileNumberRequested(): void {
    this._quoteSession.newFileNumberRequested
        .subscribe(() => {
            this.resetVars();
        },
            error => {
                this.addGrowlMsg(false, error);
            });
    }
    //#endregion INIT

    //#region GET NEW File Number
    public getNewFileNumber(): void {
        this.newFileNumber = null;
        this.displayCreateFileNumber = false;
        let request: _NewNumberRequest = new _NewNumberRequest("File Number", 1, "", "", this.newFileNumberDescription, "");
        this._quoteService.putNewNumber(request)
            .subscribe(
                newFileNumber => this.onGetNewFileNumberSuccess(newFileNumber),
                error => this.onGetNewFileNumberError(error)
            );
        
    }

    private onGetNewFileNumberSuccess(newFileNumber: any): void {
        var deserialisedFileNumber = this.deserialiseFormattedNumber(newFileNumber);
        if (deserialisedFileNumber.length == 1) {
            this.newFileNumber = deserialisedFileNumber[0];
            this.newFileNumberStr = this.newFileNumber["formattedNumber"];
            this.addGrowlMsg(true, "New File Number " + this.newFileNumberStr + " was successfully created.");
            this._quoteSession.selectedFileNumber = this.newFileNumberStr;
            this._quoteSession.newFileNumberLoaded.emit()
        }
        else {
            console.log("number-addition-component.ts: showCreateFileNumber() -> There was an error whilst getting a new File Number.");
            this.addGrowlMsg(false, "There was an error getting a new File Number.");
        }
    }

    private onGetNewFileNumberError(error: string): void {
        console.log(error);
        this.addGrowlMsg(false, error);
        if (error.toString().substring(3) == "403")
            this._quoteSession.forbiddenErrorLoaded.emit();
    }
    //#endregion GET NEW FILE NUMBER

    //#region HELPER METHODS
    private resetVars(): void {
        this.displayCreateFileNumber = true;
        this.newFileNumber = null;
        this.newFileNumberDescription = "";
    }

    private addGrowlMsg(success: boolean, message: string): void {
        this._messageService.clear();
        if (success)
            this._messageService.add({ severity: 'success', summary: 'Success', detail: message });
        else
            this._messageService.add({ severity: 'error', summary: 'Failed', detail: message });
    }
    //#endregion HELPER METHODS
}
