export class _NewNumberRequest {
    TypeCode: string;
    Quantity: number;
    EngineeringRequestNumber: string;
    MajorProductCode: string;
    FileNumber: string;
    Description: string;
    SerialNumber: string;

    constructor(t: string, q: number, e: string, m: string, d: string, s: string, fn?: string){  
        this.TypeCode = t;
        this.Quantity = q;
        this.EngineeringRequestNumber = e;
        this.MajorProductCode = m;
        this.Description = d;
        this.SerialNumber = s;
        this.FileNumber = fn;
    }
}