<div class="er-dialog">
    <p-dialog header="Create a New File Number" [(visible)]="displayCreateFileNumber" [responsive]="true" showEffect="fade" [modal]="true" [style]="{'width':'600px'}">
        <div class="row" style="padding-top:15px;">
            <div class="col-xs-12">
                <label class="basic-label">Description (Optional)</label>
            </div>
            <div class="col-xs-12">
                <textarea pInputText placeholder="Enter a description"
                cols="80" rows="6" maxlength="150" type="text" [(ngModel)]="newFileNumberDescription" style="resize: none;"></textarea>
            </div>
        </div>
      
        <ng-template pTemplate="footer">
            <button pButton class="basic-button" icon="fa fa-check" (click)="getNewFileNumber()" label="Get New File Number"></button>
        </ng-template>
      </p-dialog>
</div>



<!-- File Number Addition Success Popup -->
<app-single-addition-success [singleNumber]="newFileNumberStr"></app-single-addition-success>
