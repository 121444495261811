import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../../../_utils';
import { QuoteSession } from '../../../quote/quote.session';
import { MessageService } from 'primeng/api';
import { _ActiveNumber } from '../../../../_models/_ActiveNumber';
import { FilterService } from '../filter.service';

@Component({
    selector: 'app-filter-file-number',
    templateUrl: './filter-file-number.component.html',
    styleUrls: ['./filter-file-number.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class FilterFileNumberComponent extends BaseComponent implements OnInit {
    @Input() selectedERNumber: any;
    @Input() selectedMPC: string;
    @Input() selectedFileNumber: string;
    @Input() placeholder: string;
    @Input() modifiedRowDataList: _ActiveNumber [];
    @Input() originalRowDataList: any;
    @Input() rowData: _ActiveNumber;
    @Input() displayModifiedRows: boolean;

    private fileNumbers: any;
    public filteredFileNumbers: string[] = [];

    constructor(
        private _filterService: FilterService,
        private _quoteSession: QuoteSession,
        private _messageService: MessageService
    ) { 
        super();
    }

    ngOnInit(): void {
        this.initFileNumber();
    }
    
    private initFileNumber(): void {
        this.fileNumbers = this._quoteSession.fileNumbers;
    }

    public onCompleteMethod(event: any): void {

        let fileNumberSearchCriteria: string = event.query.toUpperCase();
        // Only process filter if the user has entered 1 or more characters (excluding "ER")
        if (fileNumberSearchCriteria.length < 1){
          this.filteredFileNumbers = [];
          return;
        }

        this.initFileNumber();
        let filtered : string[] = [];
        var errorMsgList = [];

        for(let i = 0; i < this.fileNumbers.length; i++) {
            let fileNumber: string = this.fileNumbers[i];
            if(fileNumber.includes(fileNumberSearchCriteria)) {
                filtered.push(fileNumber);
            }
        }

        if (filtered.length == 0 ) 
        {
            errorMsgList.push({
                severity: 'error', 
                summary: 'Failed', 
                detail: "No File Numbers match '" + fileNumberSearchCriteria + "'"
            });            
        } 
        else if (filtered.length == 1) 
        {
            this.selectedFileNumber = filtered[0];
            if( this.rowData != undefined ) {
                this._filterService.addRowData(this.rowData, this.modifiedRowDataList, this.originalRowDataList, this.selectedERNumber, this.selectedMPC, this.selectedFileNumber, this.displayModifiedRows);
            } else {
                this._quoteSession.numberAdditionFileNumberChanged.emit(this.selectedFileNumber);                
            }
        }

        this._messageService.addAll(errorMsgList);
        this.filteredFileNumbers = filtered;
    }
    
    public onClear(): void {
        if (this.rowData == undefined) {
            this._quoteSession.numberAdditionFileNumberChanged.emit("");            
        }
    }

    public onFocus(rowData: any): void {
        this.selectedMPC = null;
        if( rowData != undefined )
          this.selectedFileNumber = rowData["fileNumber"];
      }

    public onBlur(rowData: any): void {
        if( rowData != undefined ){ 
            this.selectedFileNumber = null;
            this.originalRowDataList.forEach(origRowData => {
              if( origRowData[0] == rowData["numberId"] && origRowData[1] == rowData["description"] && origRowData[2] == rowData["formattedEngineeringRequestNumber"]
                && origRowData[3] == rowData["majorProductCode"] && origRowData[4] == rowData["fileNumber"] )
                rowData["isEdited"] = false;
            });
        } else {
            this._quoteSession.numberAdditionFileNumberChanged.emit(this.selectedFileNumber);            
        }
    }

    public onSelect(value: string): void {
        if (this.rowData == undefined) {
            this.selectedFileNumber = value;
            this._quoteSession.numberAdditionFileNumberChanged.emit(this.selectedFileNumber);            
        }
    }

    public onNgModelChange(rowData: _ActiveNumber): void {
        if( rowData != undefined )
          this._quoteSession.hasModifications = this._filterService.addRowData(rowData, this.modifiedRowDataList, this.originalRowDataList, this.selectedERNumber, this.selectedMPC, this.selectedFileNumber, this.displayModifiedRows);
      }
}
