<p-toast life="4000"></p-toast>


<!-- Loading Spinner -->
<div *ngIf="isLoading">
    <div align="center" style="margin-top: 10em;">
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>


<!-- Main Table -->
<div class="p-datatable-resolution" *ngIf="!isLoading">
    <p-table #mainTable [resizableColumns]="true" [columns]="cols" [value]="_filteredNumberList" sortField="createdUtc" sortOrder="-1" [paginator]="true" [rows]="250" [scrollable]="true" scrollHeight="flex"  [responsive]="true"
            rowHover="true" selectionMode="single" [(selection)]="selectedRow" (onRowSelect)="onRowSelect($event.data)" dataKey="numberId" [loading]="isFiltering" (onFilter)="onFilterHandler($event.filteredValue)" filterDelay="0"> 
        <ng-template pTemplate="header" style="font-size: 1.6em; font-weight:bold;">List of Engineering Request</ng-template>
        <ng-template pTemplate="caption">
            <div style="text-align: left">
                <div class="grid">
                    <div class="col-12 md:col-1">
                        <button type="button" pButton icon="fa fa-refresh" title="Data will be pulled again from database upon refreshing" style="font-size: 1.3em; width: 3.5em" (click)="onClickRefreshBtn()" class="refresh-button" angularticsAction="DownloadClick"
angularticsLabel="label-name"
angularticsValue="value"
angularticsCategory="song"></button>
                    </div>

                    <div class="col-12 md:col-3">
                        <app-filter-user></app-filter-user>
                    </div>
                    
                    <div class="col-12 md:col-4">
                        <div class="p-inputgroup">
                            <input type="text" pInputText size="50" placeholder="Keywords (keywords must contain at least 2 characters)" [(ngModel)]="globalFilterInputStr" (ngModelChange)="highlightGlobalFilter($event)" style="width:auto; margin-left: 1.5em;" (keypress)="onKeyPressGlobalFilter($event)" title="Data returned will have each keyword somewhere in the grid row">
                            <button pButton type="button" icon="fa fa-search" (click)="confirmGlobalFiltering(globalFilterInputStr);" class="p-button-secondary" title="Data returned will have each keyword somewhere in the grid row" ></button>
                        </div>
                    </div>

                    <div class="col-12 md:col-1"></div>

                    <div class="col-12 md:col-3">
                        <div *ngIf="globalFilterInputStrSaved == globalFilterInputStr">
                            <span class="basic-label" >{{mainTable.totalRecords}} {{(mainTable.totalRecords == 1) ? 'record' : 'records'}} in {{countNumPages(mainTable.totalRecords)}} {{(countNumPages(mainTable.totalRecords) == 1) ? 'page' : 'pages'}} </span>
                        </div>
                        <div *ngIf="globalFilterInputStrSaved != globalFilterInputStr">
                            <span class="basic-label">Search Results Not Updated</span>
                        </div>
                    </div>
                </div>
            </div>    
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="flex: 0 0 2.25em; background: #ebedf0"></th>
                <th style="flex: 0 0 9em; background: #ebedf0" [pSortableColumn]="'code'"><div class="header-centered">Type <p-sortIcon [field]="'code'" [width]="9"></p-sortIcon></div></th>
                <th style="flex: 0 0 7em; background: #ebedf0" [pSortableColumn]="'formattedNumber'"><div class="header-centered">Number <p-sortIcon [field]="'formattedNumber'"></p-sortIcon></div></th>
                <th style="flex: 0 0 7.5em; background: #ebedf0" [pSortableColumn]="'formattedEngineeringRequestNumber'"><div class="header-centered">ER <p-sortIcon [field]="'formattedEngineeringRequestNumber'"></p-sortIcon></div></th>
                <th style="background: #ebedf0" [pSortableColumn]="'description'"><div class="header-centered">Description <p-sortIcon [field]="'description'"></p-sortIcon></div></th>
                <th style="flex: 0 0 7em; background: #ebedf0" [pSortableColumn]="'majorProductCode'"><div class="header-centered">MPC <p-sortIcon [field]="'majorProductCode'"></p-sortIcon></div></th>
                <th style="flex: 0 0 9em; background: #ebedf0" [pSortableColumn]="'fileNumber'"><div class="header-centered">File Number <p-sortIcon [field]="'fileNumber'"></p-sortIcon></div></th>
                <th style="flex: 0 0 15em; background: #ebedf0" [pSortableColumn]="'createdBy'"><div class="header-centered">Created By <p-sortIcon [field]="'createdBy'"></p-sortIcon></div></th>
                <th style="flex: 0 0 11em; background: #ebedf0" [pSortableColumn]="'createdUtc'"><div class="header-centered">Created <p-sortIcon [field]="'createdUtc'"></p-sortIcon></div></th>
                <th style="flex: 0 0 3em; background: #ebedf0"></th>
            </tr>
            <tr>
                <th style="flex: 0 0 2.25em; background: #ebedf0"></th>
                <th [style]='col.styletext' *ngFor="let col of columns" [ngSwitch]="col.field">
                    <p-multiSelect 
                        appendTo="body" *ngSwitchCase="'code'" [options]="typeOptions" [panelStyle]="" [style]="{'width':'100%','text-align':'left'}" defaultLabel="All"
                        (onChange)="onChangeTypeFilter(); mainTable.filter($event.value, col.field, 'in')" [maxSelectedLabels]="3" [selectedItemsLabel]="'{0} items selected'" #typeselect>
                    </p-multiSelect>  
                </th>
                <th style="flex: 0 0 3em; background: #ebedf0"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
            <tr [ngClass]="rowData.isEdited ? 'edited-row' : unedited-row" [pSelectableRow]="rowData">
                <td style="flex: 0 0 2.25em" class="expand-chevron">
                    <a href="#" [pRowToggler]="rowData" >
                        <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                    </a>
                </td>
                <!-- Type Column -->
                <td style="flex: 0 0 9em">
                    <div [ngSwitch]="rowData.code" [ngClass]="containsStr(rowData.code) ? 'highlight-match-filter-str' : 'default-match-filter-str'">
                        <div *ngSwitchCase="'MachineDesignCentre'">
                            MDC
                        </div>
                        <div *ngSwitchCase="'Industrial Engineering'">
                            IE
                        </div>
                        <div *ngSwitchCase="'Engineering Request'">
                            ER
                        </div>
                        <div *ngSwitchCase="'Manufacturing Engineering Project'">
                            ME Project
                        </div>
                        <div *ngIf="rowData.code != 'MachineDesignCentre' && rowData.code != 'Industrial Engineering' && rowData.code != 'Engineering Request' && rowData.code != 'Manufacturing Engineering Project'">
                            {{rowData.code}}
                        </div>
                    </div>
                    
                </td>
                <!-- Number Column -->
                <td style="flex: 0 0 7em">
                    <div [ngClass]="containsStr(rowData.formattedNumber) ? 'highlight-match-filter-str' : 'default-match-filter-str'">
                        {{rowData.formattedNumber}}
                    </div>
                </td>
                <!-- ER Column -->
                <td style="flex: 0 0 7.5em" pEditableColumn="rowData" [pEditableColumnField]="'formattedEngineeringRequestNumber'" [ngClass]="rowData.isEdited ? 'edited-row' : 'unedited-row'">
                    <p-cellEditor>
                        <ng-template *ngIf="rowData.code != 'Engineering Request'" pTemplate="input">
                            <div class="grid">
                                <div class="col-12 md:col-12">
                                    <app-filter-er
                                        [selectedMPC]="rowData.majorProductCode" 
                                        [selectedERNumber]="rowData.formattedEngineeringRequestNumber" 
                                        [rowData]="rowData" 
                                        [modifiedRowDataList]="modifiedRowDataList" 
                                        [originalRowDataList]="originalRowDataList" 
                                        [displayModifiedRows]="displayModifiedRows" 
                                        [selectedFileNumber]="rowData.fileNumber">
                                    </app-filter-er>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div [ngClass]="containsStr(rowData.formattedEngineeringRequestNumber) ? 'highlight-match-filter-str' : 'default-match-filter-str'">
                                {{rowData.formattedEngineeringRequestNumber}}
                            </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <!-- Description Column -->
                <td pEditableColumn [ngClass]="rowData.isEdited ? 'edited-row' : 'unedited-row'">
                    <p-cellEditor class="description-edited">
                        <ng-template pTemplate="input">
                            <textarea 
                                rows="2" cols="50" maxlength="150" type="text" onclick="this.select()" [(ngModel)]="rowData.description" 
                                (ngModelChange)="onNgModelChange(rowData);" style="resize: none;">
                            </textarea> 
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div [ngClass]="containsStr(rowData.description) ? 'highlight-match-filter-str' : 'default-match-filter-str'">
                                {{rowData.description}}
                            </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <!-- MPC Column -->
                <td style="flex: 0 0 7em" pEditableColumn="rowData" [pEditableColumnField]="'majorProductCode'" [ngClass]="rowData.isEdited ? 'edited-row' : 'unedited-row'">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="grid">
                                <div class="col-12 md:col-12">
                                    <app-filter-mpc 
                                        [selectedMPC]="rowData.majorProductCode" 
                                        [selectedERNumber]="rowData.formattedEngineeringRequestNumber" 
                                        [rowData]="rowData" [table]="mainTable"
                                        [modifiedRowDataList]="modifiedRowDataList" 
                                        [originalRowDataList]="originalRowDataList" 
                                        [displayModifiedRows]="displayModifiedRows" 
                                        [selectedFileNumber]="rowData.fileNumber">
                                    </app-filter-mpc>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div [ngClass]="containsStr(rowData.majorProductCode) ? 'highlight-match-filter-str' : 'default-match-filter-str'">
                                {{rowData.majorProductCode}}
                            </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <!-- File Number Column -->
                <td style="flex: 0 0 9em" pEditableColumn="rowData" [pEditableColumnField]="'fileNumber'" [ngClass]="rowData.isEdited ? 'edited-row' : 'unedited-row'">
                    <p-cellEditor>
                        <ng-template *ngIf="rowData.code != 'fileNumber' && rowData.code == 'Manufacturing Engineering Project'" pTemplate="input">
                            <div class="grid">
                                <div class="col-12 md:col-12">
                                    <app-filter-file-number
                                        [selectedMPC]="rowData.majorProductCode" 
                                        [selectedERNumber]="rowData.formattedEngineeringRequestNumber" 
                                        [rowData]="rowData" 
                                        [modifiedRowDataList]="modifiedRowDataList" 
                                        [originalRowDataList]="originalRowDataList" 
                                        [displayModifiedRows]="displayModifiedRows" 
                                        [selectedFileNumber]="rowData.fileNumber">
                                    </app-filter-file-number>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div [ngClass]="containsStr(rowData.fileNumber) ? 'highlight-match-filter-str' : 'default-match-filter-str'">
                                {{rowData.fileNumber}}
                            </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <!-- Created By Column -->
                <td style="flex: 0 0 15em">
                    <div [ngClass]="containsStr(rowData.createdBy) ? 'highlight-match-filter-str' : 'default-match-filter-str'">
                        {{rowData.createdBy}}
                    </div>
                </td>
                <!-- Created Column -->
                <td style="flex: 0 0 11em">
                    <div [ngClass]="containsStr(convertToLocalTime(rowData.createdUtc)) ? 'highlight-match-filter-str' : 'default-match-filter-str'">
                        {{convertToLocalTime(rowData.createdUtc)}}
                    </div>
                </td>

                <!-- Indicating Transferred Records -->
                <td style="flex: 0 0 3em">
                    <div *ngIf="rowData.isTransferred">
                        <i class="fa fa-exchange" title="This number has been transferred from the legacy database" style="color: green"></i>
                    </div>
                    <div *ngIf="rowData.isDuplicate">
                        <i class="fa fa-clone" title="There exists duplicates for this number" style="color: green"></i>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
            <tr>
                <td [attr.colspan]="3">
                    <div class="row">
                        <div class="col-xs-12">
                            <b class="modal-label">Type:</b> {{rowData.code}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <b class="modal-label">Number:</b> {{rowData.formattedNumber}}
                        </div>
                    </div>

                    <div *ngIf="rowData.code == 'MACAddress'">
                        <div class="row">
                            <div class="col-xs-12">
                                <b class="modal-label">Serial Number:</b> {{rowData.serialNumber}}
                            </div>
                        </div>
                    </div>
                    <button pButton class="basic-button" icon="fa fa-history" label="View History" (click)="showNumberHistory(rowData)"></button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary" let-rowData>
            <div style="text-align:left">
                <button type="button" class="basic-button" pButton icon="fa fa-check" (click)="saveChanges()" label="Save Changes"></button>
                <button type="button" class="basic-button" style="margin-left: 1.5em;" pButton icon="fa fa-edit" label="{{displayModifiedRows ? 'All Rows' : 'Modified Rows'}}" (click)="onClickModifiedRowsBtn()"></button>
                <button type="button" class="basic-button" style="margin-left: 1.5em;" pButton icon="fa fa-file-o"  label="Export .CSV" (click)="mainTable.exportCSV()"></button>
            </div>
        </ng-template>
    </p-table>
</div>


<!-- Modification Success Popup -->
<app-modification-success [modifiedSuccessList]="modifiedSuccessList"></app-modification-success>


<!-- Number History Popup -->
<app-number-history></app-number-history>

