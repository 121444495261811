import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../../../_utils';
import { QuoteSession } from '../../../quote/quote.session';
import { _ActiveNumber } from '../../../../_models/_ActiveNumber';
import { FilterService } from '../filter.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-filter-er',
  templateUrl: './filter-er.component.html',
  styleUrls: ['./filter-er.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FilterERComponent extends BaseComponent implements OnInit {
  // MPC filtering
  @Input() selectedERNumber: any;
  @Input() modifiedRowDataList: _ActiveNumber [];
  @Input() originalRowDataList: any;
  @Input() selectedMPC: string;
  @Input() displayModifiedRows: boolean;
  @Input() rowData: _ActiveNumber;
  @Input() placeholder: string;
  @Input() selectedFileNumber: string;

  private erNumbers: any;
  public filteredERNumbers: any [] = [];

  constructor(
    private _filterService: FilterService,
    private _quoteSession: QuoteSession,
    private _messageService: MessageService
  ) { 
    super();
  }

  ngOnInit() {
    this.initERNumber();
  }

  private initERNumber(): void {
    this.erNumbers = this._quoteSession.erNumbers;
  }

  public onCompleteMethod(event: any): void {

    let erSearchCriteria: string = event.query.toUpperCase();
    // Only process filter if the user has entered 3 or more characters (excluding "ER")
    if (erSearchCriteria.replace("ER","").length < 3){
      this.filteredERNumbers = [];
      return;
    } 

    this.initERNumber();
    let filtered : any [] = [];
    var errorMsgList = [];
    
    for(let i = 0; i < this.erNumbers.length; i++) {
      let erNumber: string = this.erNumbers[i]["formattedNumber"].toString().toUpperCase();
      if(erNumber.includes(erSearchCriteria)) {
        filtered.push(erNumber);
      }
    }
    
    if( filtered.length == 0 )
      errorMsgList.push( {severity: 'error', summary: 'Failed', detail: "No ER numbers match '" + erSearchCriteria + "'."});
    else if( filtered.length == 1 ){
      this.selectedERNumber = filtered[0];
      if( this.rowData != undefined )
        this._filterService.addRowData(this.rowData, this.modifiedRowDataList, this.originalRowDataList, this.selectedERNumber, this.selectedMPC, this.selectedFileNumber, this.displayModifiedRows);
      else
        this._quoteSession.numberAdditionERNumberChanged.emit(this.selectedERNumber);
    }

    this._messageService.addAll(errorMsgList);
    this.filteredERNumbers = filtered;
  }

  public onClear(rowData: any): void {
    if( rowData == undefined ){
      this._quoteSession.numberAdditionERNumberChanged.emit("");
    }
  }


  public onFocus(rowData: any): void {
    this.selectedMPC = null;
    if( rowData != undefined )
      this.selectedERNumber = rowData["formattedEngineeringRequestNumber"];
  }


  public onBlur(rowData: any): void {
    if( rowData != undefined ){ 
      this.selectedERNumber = null;
      this.originalRowDataList.forEach(origRowData => {
        if( origRowData[0] == rowData["numberId"] && origRowData[1] == rowData["description"] && origRowData[2] == rowData["formattedEngineeringRequestNumber"]
          && origRowData[3] == rowData["majorProductCode"] && origRowData[4] == rowData["fileNumber"])
          rowData["isEdited"] = false;
      });
    } else {
      this._quoteSession.numberAdditionERNumberChanged.emit(this.selectedERNumber);
    }
  }


  public onSelect(value: string): void {
    if( this.rowData == undefined ){
      this.selectedERNumber = value;
      this._quoteSession.numberAdditionERNumberChanged.emit(this.selectedERNumber);
    }
  }


  public onNgModelChange(rowData: _ActiveNumber): void {
    if( rowData != undefined )
      this._quoteSession.hasModifications = this._filterService.addRowData(rowData, this.modifiedRowDataList, this.originalRowDataList, this.selectedERNumber, this.selectedMPC, this.selectedFileNumber, this.displayModifiedRows);
  }
}
